import * as t from 'io-ts';
import type { Store } from 'redux';

import type { Id } from './api';
import { tCanPayTransaction } from './canPayTransaction';
import { tReservationCartProduct } from './cart';
import type { ReservationCartProduct } from './cart';
import type { Coordinates } from './coordinates';
import type { DeepReadonly } from './deepReadonly';
import type { PaymentMethod } from './paymentMethod';
import { tReservationMode } from './reservationMode';
import type { ReservationMode } from './reservationMode';
import { tCustomerReservationDetailsStore } from './store';

interface Membership {
  is_member: boolean;
  member_since: number | null;
}

export const tReservationStatus = t.keyof({
  pending: null,
  verification: null,
  staff_member_review: null,
  preparing: null,
  delivery_started: null,
  delivery_finished: null,
  ready_for_pickup: null,
  finished: null,
  with_review: null,
  cancelled: null,
  dismissed: null,
  finished_without_review: null,
});

export type ReservationStatus = t.TypeOf<typeof tReservationStatus>;

interface StatusHistoryItem {
  changed_at: number;
  text: string;
}

type ReservationType =
  | 'incoming'
  | 'ongoing'
  | 'completed'
  | 'not_completed'
  | 'assigned';

export type CurbsidePickupSetting = 'curbside' | 'pickup' | undefined;

const tCustomerReservationEmployee = t.interface({
  name: t.union([t.string, t.null]),
  phone: t.union([t.string, t.null]),
});

export type CustomerReservationEmployee = t.TypeOf<
  typeof tCustomerReservationEmployee
>;

export type ReviewableReservationType = {
  created_at: number;
  employee: { name: string };
  id: Id;
  product_count: number;
  store: Store;
  total_price: number | string;
};

const tReservationStore = t.interface({
  address: t.string,
  id: t.number,
  prepay: t.boolean,
  photo: t.union([t.string, t.null]),
  url_slug: t.string,
  time_zone_identifier: t.string,
  name: t.string,
});

export type ReservationStore = DeepReadonly<t.TypeOf<typeof tReservationStore>>;

// Marketplace::Reservation::History
export const tCustomerReservationHistory = t.interface({
  id: t.number,
  amount: t.string,
  reservation_mode: tReservationMode,
  dismissed_message: t.union([t.string, t.null]),
  prepaid: t.boolean,
  status: tReservationStatus,
  rating: t.union([t.number, t.null]),
  delivery_finished_at: t.number,
  products_count: t.number,
  delivery_started_at: t.number,
  checked_out_at_with_store_timezone: t.number,
  employee: tCustomerReservationEmployee,
  store: tReservationStore,
});

export type CustomerReservationHistory = t.TypeOf<
  typeof tCustomerReservationHistory
>;

export const tLocalizedProductContent = t.interface({
  brand_subtype: t.union([t.string, t.null]),
  category: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  image_urls: t.union([t.array(t.string), t.null]),
  name: t.union([t.string, t.null]),
  product_id: t.number,
});

export type LocalizedProductContent = t.TypeOf<typeof tLocalizedProductContent>;

// Marketplace::Reservation::Details::Serializer
export const tCustomerReservationDetails = t.interface({
  address: t.union([t.string, t.null]),
  aeropay_preauthorization: t.union([t.boolean, t.undefined]),
  aeropay_transaction: t.union([t.boolean, t.undefined]),
  all_discounts_total: t.number,
  brand_discounts_service_fee_total: t.union([t.number, t.undefined]),
  brand_discounts_total: t.union([t.number, t.null, t.undefined]),
  brand_discounts_total_with_fee: t.union([t.number, t.undefined]),
  cancellable: t.boolean,
  can_pay_transaction: t.union([t.null, tCanPayTransaction]),
  canpay_v2_transaction: t.union([t.boolean, t.undefined]),
  canpay_v2remotepay_transaction: t.union([t.boolean, t.undefined]),
  cart_level_discount_total: t.number,
  checked_out_at_with_store_timezone: t.number,
  created_at: t.number,
  crm_points: t.union([t.number, t.null]),
  curbside_customer_arrived: t.union([t.boolean, t.null]),
  curbside_pickup_selected: t.union([t.boolean, t.null]),
  delivery_fee_amount: t.union([t.number, t.string]),
  delivery_finished_at: t.number,
  delivery_started_at: t.number,
  dismissed_message: t.union([t.string, t.null]),
  employee: tCustomerReservationEmployee,
  hypur_transaction: t.boolean,
  id: t.union([t.number, t.string]),
  item_total: t.number,
  jane_pay_preauthorization: t.union([t.boolean, t.undefined]),
  jane_pay_service_fee_amount: t.number,
  jane_pay_transaction: t.union([t.boolean, t.undefined]),
  localized_products_content: t.array(tLocalizedProductContent),
  moneris_preauthorization: t.union([t.boolean, t.undefined]),
  moneris_transaction: t.union([t.boolean, t.undefined]),
  order_total: t.union([t.number, t.string]),
  payfirma_transaction: t.union([t.boolean, t.undefined]),
  paytender_transaction: t.boolean,
  prepaid: t.boolean,
  product_count: t.number,
  products: t.array(tReservationCartProduct),
  rating_text: t.union([t.string, t.null]),
  rating: t.union([t.number, t.null]),
  reservation_mode: tReservationMode,
  rounding_amount: t.union([t.number, t.string, t.null]),
  sales_tax_amount: t.union([t.number, t.string]),
  sales_tax_rate: t.union([t.number, t.null]),
  service_fee_amount: t.union([t.number, t.string]),
  square_transaction: t.union([t.boolean, t.undefined]),
  status: tReservationStatus,
  store_tax_amount: t.union([t.number, t.string]),
  store: tCustomerReservationDetailsStore,
  stronghold_preauthorization: t.union([t.boolean, t.undefined]),
  tax_included: t.boolean,
  tip_amount: t.number,
  total_price: t.union([t.number, t.string]),
  uuid: t.string,
});

export const tGuestCustomerReservationDetails = t.interface({
  uuid: t.string,
  store: tCustomerReservationDetailsStore,
  cancellable: t.boolean,
  curbside_pickup_selected: t.union([t.boolean, t.null]),
  curbside_customer_arrived: t.union([t.boolean, t.null]),
  cart_status: tReservationStatus,
  dismissed_message: t.union([t.string, t.null]),
  reservation_mode: tReservationMode,
});

export type CustomerReservationDetails = DeepReadonly<
  t.TypeOf<typeof tCustomerReservationDetails>
>;

export type GuestCustomerReservationDetails = DeepReadonly<
  t.TypeOf<typeof tGuestCustomerReservationDetails>
>;

export interface ExtendedReservationEmployee {
  id: Id;
  name: string;
}

// ReservationSerializer
export type Reservation = DeepReadonly<{
  address: string;
  checked_out_at: number;
  coordinates: Coordinates;
  created_at: number;
  delivery_finished_at: number;
  delivery_started_at: number;
  employee: ExtendedReservationEmployee;
  finished_time: number;
  has_special: boolean;
  id: Id;
  preauthorized: boolean;
  prepaid: boolean;
  queue_number: string;
  rating: number;
  reservation_mode: ReservationMode;
  review: string | null;
  status: ReservationStatus;
  store_coordinates: Coordinates;
  store_id: number;
  tax_included: boolean;
  user_full_name: string;
  user_nickname: string;
}>;

interface CanpayV2remotepayTransaction {
  id: number;
  status: string;
  transaction_modification_url: string;
}

// Business::Reservations::Serializer
export type ExtendedReservation = Reservation &
  DeepReadonly<{
    all_discounts_total: number;
    business_paperwork: string;
    canpay_v2remotepay_transaction?: CanpayV2remotepayTransaction;
    cart_level_discount_total: number;
    customer_paperwork: string;
    delivery_fee_amount: number;
    discounted_subtotal: number;
    government_photo_url: string;
    item_total: number;
    jane_pay_service_fee_amount: number;
    manager_name: string | null;
    membership: Membership;
    message: string;
    mmj_card_back_url: string;
    mmj_card_url: string;
    order_total: number;
    payment_method: PaymentMethod;
    products: ReservationCartProduct[];
    rounding_amount: number | null;
    sales_tax_amount: number;
    sales_tax_rate: number;
    service_fee_amount: number;
    show_paperwork: boolean;
    store_id: Id;
    store_tax_amount: number;
    student_photo_url: string;
    timeline: StatusHistoryItem[];
    tip_amount: number;
    type: ReservationType;
    user_birth_date: any;
    user_phone: string;
    veteran_photo_url: string;
  }>;
