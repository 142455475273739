import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Box,
  Button,
  ConditionalWrapper,
  Flex,
  InfoIcon,
  Popover,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { getJaneGoldFormattedLabel } from '@jane/shared/util';

import type { JaneGoldFeeBreakdownProps } from '../janeGoldCallout.types';
import { JaneGoldLearnMoreLink } from '../janeGoldLearnMoreLink';
import { JaneGoldTermsLink } from '../janeGoldTermsLink';

export const JaneGoldFeeBreakdown = ({
  buttonLabel = 'Link bank account',
  description,
  discountLabel,
  feeLabel,
  onLinkAccountClick,
  totalLabel,
  variant,
}: JaneGoldFeeBreakdownProps) => {
  const { selectedBankAccount, userHasLinkedBank } = useJaneGoldData();
  const isMobile = useMobileMediaQuery({});
  const disableJaneGoldUnregisteredRedemption = useFlag(
    FLAGS.janeGoldRedemption
  );

  const hasBankData =
    selectedBankAccount &&
    selectedBankAccount.name &&
    selectedBankAccount.bank_name;

  const depositDescription = `Your brand-sponsored cash back reward will be deposited to
    your linked bank account at ${selectedBankAccount?.bank_name} (${selectedBankAccount?.name}) in 1-3
    days.`;

  const expiredCashBack =
    variant === 'order' &&
    !userHasLinkedBank &&
    disableJaneGoldUnregisteredRedemption;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography>Cash back</Typography>
        <Typography
          color={expiredCashBack ? 'grays-mid' : 'grays-black'}
          strikeThrough={expiredCashBack}
        >
          {getJaneGoldFormattedLabel(totalLabel)}
        </Typography>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" pt={8}>
        <Flex alignItems="center">
          <Typography>Service fee</Typography>
          <Popover
            openOn="hover"
            target={<InfoIcon size="sm" />}
            children={
              <Popover.Content>
                <Typography>
                  Jane charges a service fee to process Jane Gold related
                  products and services.
                </Typography>
              </Popover.Content>
            }
            label="Learn more about Jane's service fee"
            ml={4}
          />
        </Flex>
        <Typography
          color={expiredCashBack ? 'grays-mid' : 'grays-black'}
          strikeThrough={expiredCashBack}
        >
          {getJaneGoldFormattedLabel(feeLabel, true)}
        </Typography>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" pt={8}>
        <Typography variant={variant !== 'cart' ? 'body-bold' : 'body'}>
          Estimated total cash back{expiredCashBack && ' (expired)'}
        </Typography>
        <Typography
          ml={16}
          color={expiredCashBack ? 'grays-mid' : 'grays-black'}
          variant={variant !== 'cart' ? 'body-bold' : 'body'}
          strikeThrough={expiredCashBack}
        >
          {typeof discountLabel === 'number'
            ? getJaneGoldFormattedLabel(discountLabel)
            : discountLabel}
        </Typography>
      </Flex>
      {userHasLinkedBank && hasBankData ? (
        <Typography variant="mini" color="text-light" pt={8}>
          {depositDescription}
          &nbsp;
          <JaneGoldLearnMoreLink userHasLinkedBank={true} finePrint />
        </Typography>
      ) : expiredCashBack ? null : (
        <Flex
          flexDirection={isMobile || variant === 'order' ? 'column' : 'row'}
          justifyContent="space-between"
          pt={variant === 'order' ? (isMobile ? 24 : 8) : 24}
        >
          <Typography
            variant={variant === 'order' ? 'mini' : 'body'}
            color={variant === 'order' ? 'text-light' : 'text-main'}
            pb={variant === 'order' ? (isMobile ? 24 : 8) : isMobile ? 16 : 0}
            pr={!isMobile && variant !== 'order' ? 24 : 0}
          >
            {description}
            &nbsp;
            <JaneGoldTermsLink finePrint={variant === 'order'} />
          </Typography>
          <ConditionalWrapper
            condition={variant === 'order'}
            wrapper={(children) => (
              <Box
                minWidth={200}
                maxWidth={!isMobile ? 160 : undefined}
                ml={!isMobile ? 'auto' : undefined}
              >
                {children}
              </Box>
            )}
          >
            <Button
              full={isMobile}
              onClick={onLinkAccountClick}
              variant="secondary"
              label={buttonLabel}
            />
          </ConditionalWrapper>
        </Flex>
      )}
    </Box>
  );
};
