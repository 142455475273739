import { api, generateTrackingUrls, generateUrl } from '../api';
import {
  TopOfMenuRowProduct as RecommendedPDPRowProduct,
  TopOfMenuRowClient,
} from '../topOfMenuRow';
import type {
  FetchTopOfMenuRowApiResponse,
  TopOfMenuRowProductProps,
  TopOfMenuRowRenderPayload,
} from '../topOfMenuRow';
import { zoneStorePdp } from '../types/zones';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateRecommendedPDPRowProps,
  RecommendedPDPRowProps,
} from './recommendedPDPRow.types';

/**
 * Represents a recommended row placement in a product's details page.
 *
 * In a server side context, this instance can be recreated over to the
 * client by using the **unmodified** properties provided by the `serialize()`
 * method.
 */
export class RecommendedPDPRow {
  /**
   * The title of the placement.
   * @default 'Discover additional products'
   */
  public title = 'Discover additional products' as const;
  /**
   * A mix of organic and sponsored products for this placement.
   * @example
   * ```jsx
   * placement.products.map((product) => {
   *   return (<ProductCard
   *     product={product}
   *     onClick={() => product.click()}
   *     key={product.productId}
   *   />);
   * });
   * ```
   */
  public products: RecommendedPDPRowProduct[];

  private renderEndpoint: string;
  private renderPayload: TopOfMenuRowRenderPayload;
  private hasBeenRendered: boolean;

  /**
   * Builds a new RecommendedPDPRow instance based on the provided properties.
   * This method should be used in conjunction with `serialize()` to recreate the instance.
   */
  constructor(props: RecommendedPDPRowProps) {
    this.products = props.products.map(
      (productProps) => new RecommendedPDPRowProduct(productProps)
    );
    this.renderPayload = props.renderPayload;
    this.renderEndpoint = props.renderEndpoint;
    this.hasBeenRendered = props.hasBeenRendered ?? false;
  }

  /**
   * Records attribution data for rendering of the placement.
   * @example
   * ```jsx
   * function RecommendedPDPRowComponent({ placement }) {
   *   useEffect(() => {
   *     placement.render();
   *   }, []);
   *
   *   return (<div>...</div>);
   * }
   * ```
   */
  public render(): Promise<void> {
    if (this.hasBeenRendered) {
      return Promise.resolve();
    }

    return api.post(this.renderEndpoint, this.renderPayload).then(() => {
      this.hasBeenRendered = true;
    });
  }

  /**
   * Converts the object in a JSON-serializable data structure that should be
   * used **unmodified** in the constructor to create a new instance of this class.
   * This method should be used in conjunction with `new RecommendedPDPRow()` to recreate the instance.
   *
   * This function is especially helpful in a server-side environment to
   * keep track of the same instance across client side and server side
   * rendered components.
   * @example
   * ```jsx
   * let serializedObj = obj.serialize();
   * let deserializedObj = new RecommendedPDPRow(serializedObj);
   * ```
   */
  public serialize(): RecommendedPDPRowProps {
    return {
      hasBeenRendered: this.hasBeenRendered,
      products: this.products.map((product) => product.serialize()),
      renderEndpoint: this.renderEndpoint,
      renderPayload: this.renderPayload,
    };
  }

  /**
   * Converts the object to a JSON string, using `serialize()` behind the
   * scenes. The returned value should be used **unmodified** in the
   * `RecommendedPDPRow.fromJSON()` static method.
   * This method should be used in conjunction with `RecommendedPDPRow.fromJSON()` to recreate the instance.
   */
  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  /**
   * Converts the previously-serialized object back to an instance of this class.
   * This method should be used in conjunction with `toJSON()` to recreate the instance.
   */
  public static fromJSON(serialized: string): RecommendedPDPRow {
    const { products, renderPayload, renderEndpoint, hasBeenRendered } =
      typedJSONParse<RecommendedPDPRowProps>(serialized);

    return new RecommendedPDPRow({
      hasBeenRendered,
      products,
      renderEndpoint,
      renderPayload,
    });
  }
}

export class RecommendedPDPRowClient extends TopOfMenuRowClient {
  private generatePDPUrl(options: CreateRecommendedPDPRowProps): string {
    const url = `/v1/stores/${options.storeId}/sponsored`;

    const searchParams = new URLSearchParams({
      app_mode: options.appMode,
      jane_device_id: options.identifier.jdid,
      mp_distinct_id: options.identifier.jdid,
      zone: zoneStorePdp,
    });

    searchParams.set('excluded_brand_names', options.currentBrandName);

    if (options.filters) {
      const filterRootTypes = options.filters.rootTypes?.join(',');

      if (filterRootTypes) {
        searchParams.set('current_root_types', filterRootTypes);
      }

      const filterLineages = options.filters.categories?.join(',');

      if (filterLineages) {
        searchParams.set('current_lineages', filterLineages);
      }
    }

    return generateUrl(options.endpoint, `${url}?${searchParams.toString()}`, {
      apiKey: options.apiKey,
      source: options.source,
      version: options.version,
    });
  }

  public async createRecommendedPDPRow(
    props: CreateRecommendedPDPRowProps
  ): Promise<RecommendedPDPRow> {
    const url = this.generatePDPUrl(props);

    const response = (await api.get(url)) as FetchTopOfMenuRowApiResponse;
    const { clickEndpoint, impressionEndpoint } = generateTrackingUrls(
      props.eventsEndpoint,
      { apiKey: props.apiKey, source: props.source, version: props.version }
    );

    const products: TopOfMenuRowProductProps[] =
      response.products?.map((product) => ({
        attributes: {},
        clickEndpoint,
        clickPayload: this.generateProductClickPayload(
          product,
          response.flight?.kevel_token,
          props.identifier
        ),
        productId: product.product_id,
      })) ?? [];

    const renderPayload = this.generateRenderPayload(
      response.products ?? [],
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    return new RecommendedPDPRow({
      products,
      renderEndpoint: impressionEndpoint,
      renderPayload,
    });
  }
}
